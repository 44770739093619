import { useSearchParams } from "@remix-run/react";

import { UrlUtil } from "@wind/util";

const useStartUrl = () => {
  const [searchParams] = useSearchParams();

  return UrlUtil.makePath("/start", searchParams);
};

export default useStartUrl;
